<template>
    <div>
        <section class="my-0" :class="{'section': desktop}">
            <div class="lead container mb-4">{{$t('notification.dashboard.popover')}}.</div>
            <card body-classes="px-lg-6"
                  class="container border-0">
                <template>
                    <div class="row">
                        <div class="col">
                            <card shadow :hover="desktop" class="col">
                                <div class="row">
                                    <div class="col">
                                        <h3>{{$t('credit.basic.name')}}</h3>
                                    </div>
                                </div>
                                <div class="mt-4"><hr /></div>
                                <div class="row">
                                    <div class="col">
                                        <h3><strong>{{$t('credit.basic.quantity')}} <br /> {{$t('credit.title')}}</strong></h3>
                                    </div>
                                </div>
                                <div class="mt-4"><hr /></div>
                                <div class="row">
                                    <div class="col">
                                        <h5>R$ {{$t('credit.basic.price')}} {{$t('credit.currency')}}</h5>
                                        <small>R$ {{$t('credit.basic.price')/$t('credit.basic.quantity')}}{{$t('credit.subtitle')}}</small>
                                    </div>
                                </div>
                                <div class="row py-2">
                                    <div class="col">
                                        <base-button tag="a" @click="clickBuy('Basic', 50, 1)" :href="preferences[0]" textColor="white" target="_blank" block type="primary" class="mt-3">{{$t('credit.choose')}}</base-button>
                                    </div>
                                </div>
                            </card>
                        </div>

                        <div class="col" :class="{'mt-4':!desktop}">
                            <card shadow :hover="desktop" class="col">
                                <div class="row">
                                    <div class="col">
                                        <h3>{{$t('credit.advanced.name')}}</h3>
                                    </div>
                                </div>
                                <div class="mt-4"><hr /></div>
                                <div class="row">
                                    <div class="col">
                                        <h3><strong>{{$t('credit.advanced.quantity')}} <br /> {{$t('credit.title')}}</strong></h3>
                                    </div>
                                </div>
                                <div class="mt-4"><hr /></div>
                                <div class="row">
                                    <div class="col">
                                        <h5>R$ {{$t('credit.advanced.price')}} {{$t('credit.currency')}}</h5>
                                        <small>R$ {{$t('credit.advanced.price')/$t('credit.advanced.quantity')}}{{$t('credit.subtitle')}}</small>
                                    </div>
                                </div>
                                <div class="row py-2">
                                    <div class="col">
                                        <base-button tag="a" @click="clickBuy('Advanced', 175, 2)" :href="preferences[1]" textColor="white" target="_blank" block type="primary" class="mt-3">{{$t('credit.choose')}}</base-button>
                                    </div>
                                </div>
                            </card>
                        </div>

                        <div class="col" :class="{'mt-4':!desktop}">
                            <card shadow :hover="desktop" class="col">
                                <div class="row">
                                    <div class="col">
                                        <h3>{{$t('credit.professional.name')}}</h3>
                                    </div>
                                </div>
                                <div class="mt-4"><hr /></div>
                                <div class="row">
                                    <div class="col">
                                        <h3><strong>{{$t('credit.professional.quantity')}} <br /> {{$t('credit.title')}}</strong></h3>
                                    </div>
                                </div>
                                <div class="mt-4"><hr /></div>
                                <div class="row">
                                    <div class="col">
                                        <h5>R$ {{$t('credit.professional.price')}} {{$t('credit.currency')}}</h5>
                                        <small>R$ {{$t('credit.professional.price')/$t('credit.professional.quantity')}}{{$t('credit.subtitle')}}</small>
                                    </div>
                                </div>
                                <div class="row py-2">
                                    <div class="col">
                                        <base-button tag="a" @click="clickBuy('Professional', 250, 3)" :href="preferences[2]" textColor="white" target="_blank" block type="primary" class="mt-3">{{$t('credit.choose')}}</base-button>
                                    </div>
                                </div>
                            </card>
                        </div>
                    </div>
                </template>
            </card>
        </section>
        <!--<div class="vld-parent">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 loader="dots"
                 :is-full-page="true"
                 :opacity="1"
                 color="#5271ff"></loading>
    </div>-->
    </div>
</template>

<script>
    // import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import $ from 'jquery';

    export default {       
        name: 'Credit',

        data() {
            return {
                preferences: [],
                isLoading: false,
                desktop: screen.width > 700
            }
        },

        methods: {
            loadPreferences(preferences) {
                this.preferences = preferences;
            },

            clickBuy(name, price, position) {
                this.$gtag.event(
                    'select_content', {
                        'content_type': 'product',
                        'items': [
                            {
                                "name": name,
                                "list_position": position,
                                "quantity": 1,
                                "price": price
                            }
                        ]
                    }
                )
            },
            load(field, data) {
                this.$data[field] = data;
            },
        },

        mounted() {
            var load = this.load;
            this.$gtag.set({ 'user_id': this.$store.state._id });
            var loadPreferences = this.loadPreferences;
            $.ajax({
                url: 'https://api.immobl.com/payment',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                success: function (response) {
                    if (response.success) {
                        loadPreferences(response.preferences);
                        load('isLoading', false);
                    }
                },
                //error: function (err) {
                    //console.log(err);
                //}
            });
        }

};
</script>

<style scoped>
    .Gmap {
        width: 65%;
        height: 650px;
        float: left;
        /*bottom: 0;*/
        position: absolute;
    }

    .properties {
        width: 35%;
        float: right;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 650px;
        bottom: 0;
        position: relative;
        margin-bottom: 0px;
    }

    .form {
        width: 50%;
        margin-left: 6px;
    }

    img {
        max-width: 70px;
        float: left;
    }
</style>